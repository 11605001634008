body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
}

a,
a:active,
a:visited,
a:hover {
  text-decoration: none;
}

.container-fluid {
  animation: hcFadeIn 0.6s ease-in-out;
  min-height: 75vh;
}

.container-fluid:not(.Home):not(.VenuesList) {
  margin-top: 110px;
}

.navbar-light .navbar-toggler {
  border: none;
}

.nav-link,
.nav-item {
  color: rgba(255, 255, 255, 0.9);
  font-size: 32px;
}

@media (min-width: 801px) {
  .nav-link,
  .nav-item {
    font-size: 18px;
  }
}

.nav-link:hover,
.nav-item:hover {
  color: rgba(255, 255, 255, 0.7);
}

.btn {
  padding: 10px 20px;
  min-width: 90px;
  border-radius: 1000px;
}

.btn-primary {
  background: #154b8b;
}

.btn-danger {
  background: #c4252d;
}

.message {
  margin: 10px;
  max-width: 600px;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.message.green {
  background: rgba(48, 169, 49, 0.8);
}

.message.red {
  background: rgba(204, 35, 55, 0.8);
}

.message.blue {
  background: rgba(14, 85, 130, 0.6);
}

.message.orange {
  background: rgba(255, 159, 97, 0.8);
}

table.table-hover td:hover {
  cursor: pointer;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.7);
}

@keyframes hcFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
