.Home {
  font-size: large;
  text-align: center;
  padding: 0;
}

.Home-intro {
  font-size: 32px;
  color: white;
  margin-bottom: 25px;
}

.hero-text {
  width: 300px;
  height: 250px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  margin: 180px auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.game-venue-section {
  display:flex;
}

.contact-container {
  width: 100vw;
  height: 100vh;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: no-repeat center center;
  background-size: cover;
}

@media screen and (min-width: 601px) {
  .hero-text {
    width: 600px;
    height: 350px;
  }
  .Home-intro {
    font-size: 48px;
  }
}

@media screen and (min-width: 801px) {
  .hero-text {
    width: 700px;
    height: 350px;
  }
  .Home-intro {
    font-size: 68px;
  }
  
  .host-game-landing {
    width: 40vw;
  }
}

.landing-button {
  padding: 20px;
  display: flex;
  align-items: center;
  color: rgba(255,255,255,0.8);
  background: transparent;
  border: 2px solid rgba(255,255,255,0.8);
}

.landing-button:hover {
  color: #19518b;
  background: white;
}
