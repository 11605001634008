body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
}

a,
a:active,
a:visited,
a:hover {
  text-decoration: none;
}

.container-fluid {
  -webkit-animation: hcFadeIn 0.6s ease-in-out;
          animation: hcFadeIn 0.6s ease-in-out;
  min-height: 75vh;
}

.container-fluid:not(.Home):not(.VenuesList) {
  margin-top: 110px;
}

.navbar-light .navbar-toggler {
  border: none;
}

.nav-link,
.nav-item {
  color: rgba(255, 255, 255, 0.9);
  font-size: 32px;
}

@media (min-width: 801px) {
  .nav-link,
  .nav-item {
    font-size: 18px;
  }
}

.nav-link:hover,
.nav-item:hover {
  color: rgba(255, 255, 255, 0.7);
}

.btn {
  padding: 10px 20px;
  min-width: 90px;
  border-radius: 1000px;
}

.btn-primary {
  background: #154b8b;
}

.btn-danger {
  background: #c4252d;
}

.message {
  margin: 10px;
  max-width: 600px;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.message.green {
  background: rgba(48, 169, 49, 0.8);
}

.message.red {
  background: rgba(204, 35, 55, 0.8);
}

.message.blue {
  background: rgba(14, 85, 130, 0.6);
}

.message.orange {
  background: rgba(255, 159, 97, 0.8);
}

table.table-hover td:hover {
  cursor: pointer;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.7);
}

@-webkit-keyframes hcFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hcFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Home {
  font-size: large;
  text-align: center;
  padding: 0;
}

.Home-intro {
  font-size: 32px;
  color: white;
  margin-bottom: 25px;
}

.hero-text {
  width: 300px;
  height: 250px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  margin: 180px auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.game-venue-section {
  display:flex;
}

.contact-container {
  width: 100vw;
  height: 100vh;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: no-repeat center center;
  background-size: cover;
}

@media screen and (min-width: 601px) {
  .hero-text {
    width: 600px;
    height: 350px;
  }
  .Home-intro {
    font-size: 48px;
  }
}

@media screen and (min-width: 801px) {
  .hero-text {
    width: 700px;
    height: 350px;
  }
  .Home-intro {
    font-size: 68px;
  }
  
  .host-game-landing {
    width: 40vw;
  }
}

.landing-button {
  padding: 20px;
  display: flex;
  align-items: center;
  color: rgba(255,255,255,0.8);
  background: transparent;
  border: 2px solid rgba(255,255,255,0.8);
}

.landing-button:hover {
  color: #19518b;
  background: white;
}

.login-register {
  max-width: 600px;
  margin: 20px auto;
}

.tabs {
  display: flex;
	margin: 0;
	padding: 0;
	list-style: none;
	border-radius: 5px 5px 0 0;
}
.tab-link {
	display: inline-block;
	padding: 10px 0;
	cursor: pointer;
	flex: 1 1;
	text-align: center;
  border-radius: 5px 5px 0 0;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #154b8b;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.tab-link.active {
  background: #154b8b;
  color: #fff;
}

.active-tab-content{
	padding: 15px;
  border: 1px solid #154b8b;
}
/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}
.game-detail {
  width: 100%;
  padding: 10px;
  background: #f8f9fa;
  border: 2px solid #154b8b;
  box-shadow: 1px 1px 1px #154b8b;
  border-radius: 5px;
  margin: 10px auto;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: inherit !important;
}

.buttons-section {
  margin-top: 10px;
}

@media only screen and (min-width: 801px) {
  .game-detail {
    width: 60%;
    /* animation: fadeIn .6s ease-in-out; */
  }
}

/* @keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

/*
==============================================
CSS3 ANIMATION CHEAT SHEET
==============================================

Made by Justin Aguilar

www.justinaguilar.com/animations/

Questions, comments, concerns, love letters:
justin@justinaguilar.com
==============================================
*/

/*
==============================================
slideDown
==============================================
*/


.slideDown{
	animation-name: slideDown;
	-webkit-animation-name: slideDown;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;	

	visibility: visible !important;						
}

@keyframes slideDown {
	0% {
		-webkit-transform: translateY(-100%);
		        transform: translateY(-100%);
	}
	50%{
		-webkit-transform: translateY(8%);
		        transform: translateY(8%);
	}
	65%{
		-webkit-transform: translateY(-4%);
		        transform: translateY(-4%);
	}
	80%{
		-webkit-transform: translateY(4%);
		        transform: translateY(4%);
	}
	95%{
		-webkit-transform: translateY(-2%);
		        transform: translateY(-2%);
	}			
	100% {
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}		
}

@-webkit-keyframes slideDown {
	0% {
		-webkit-transform: translateY(-100%);
	}
	50%{
		-webkit-transform: translateY(8%);
	}
	65%{
		-webkit-transform: translateY(-4%);
	}
	80%{
		-webkit-transform: translateY(4%);
	}
	95%{
		-webkit-transform: translateY(-2%);
	}			
	100% {
		-webkit-transform: translateY(0%);
	}	
}

/*
==============================================
slideUp
==============================================
*/


.slideUp{
	animation-name: slideUp;
	-webkit-animation-name: slideUp;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;

	visibility: visible !important;			
}

@keyframes slideUp {
	0% {
		-webkit-transform: translateY(100%);
		        transform: translateY(100%);
	}
	50%{
		-webkit-transform: translateY(-8%);
		        transform: translateY(-8%);
	}
	65%{
		-webkit-transform: translateY(4%);
		        transform: translateY(4%);
	}
	80%{
		-webkit-transform: translateY(-4%);
		        transform: translateY(-4%);
	}
	95%{
		-webkit-transform: translateY(2%);
		        transform: translateY(2%);
	}			
	100% {
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}	
}

@-webkit-keyframes slideUp {
	0% {
		-webkit-transform: translateY(100%);
	}
	50%{
		-webkit-transform: translateY(-8%);
	}
	65%{
		-webkit-transform: translateY(4%);
	}
	80%{
		-webkit-transform: translateY(-4%);
	}
	95%{
		-webkit-transform: translateY(2%);
	}			
	100% {
		-webkit-transform: translateY(0%);
	}	
}

/*
==============================================
slideLeft
==============================================
*/


.slideLeft{
	animation-name: slideLeft;
	-webkit-animation-name: slideLeft;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;		

	visibility: visible !important;	
}

@keyframes slideLeft {
	0% {
		-webkit-transform: translateX(150%);
		        transform: translateX(150%);
	}
	50%{
		-webkit-transform: translateX(-8%);
		        transform: translateX(-8%);
	}
	65%{
		-webkit-transform: translateX(4%);
		        transform: translateX(4%);
	}
	80%{
		-webkit-transform: translateX(-4%);
		        transform: translateX(-4%);
	}
	95%{
		-webkit-transform: translateX(2%);
		        transform: translateX(2%);
	}			
	100% {
		-webkit-transform: translateX(0%);
		        transform: translateX(0%);
	}
}

@-webkit-keyframes slideLeft {
	0% {
		-webkit-transform: translateX(150%);
	}
	50%{
		-webkit-transform: translateX(-8%);
	}
	65%{
		-webkit-transform: translateX(4%);
	}
	80%{
		-webkit-transform: translateX(-4%);
	}
	95%{
		-webkit-transform: translateX(2%);
	}			
	100% {
		-webkit-transform: translateX(0%);
	}
}

/*
==============================================
slideRight
==============================================
*/


.slideRight{
	animation-name: slideRight;
	-webkit-animation-name: slideRight;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;		

	visibility: visible !important;	
}

@keyframes slideRight {
	0% {
		-webkit-transform: translateX(-150%);
		        transform: translateX(-150%);
	}
	50%{
		-webkit-transform: translateX(8%);
		        transform: translateX(8%);
	}
	65%{
		-webkit-transform: translateX(-4%);
		        transform: translateX(-4%);
	}
	80%{
		-webkit-transform: translateX(4%);
		        transform: translateX(4%);
	}
	95%{
		-webkit-transform: translateX(-2%);
		        transform: translateX(-2%);
	}			
	100% {
		-webkit-transform: translateX(0%);
		        transform: translateX(0%);
	}	
}

@-webkit-keyframes slideRight {
	0% {
		-webkit-transform: translateX(-150%);
	}
	50%{
		-webkit-transform: translateX(8%);
	}
	65%{
		-webkit-transform: translateX(-4%);
	}
	80%{
		-webkit-transform: translateX(4%);
	}
	95%{
		-webkit-transform: translateX(-2%);
	}			
	100% {
		-webkit-transform: translateX(0%);
	}
}

/*
==============================================
slideExpandUp
==============================================
*/


.slideExpandUp{
	animation-name: slideExpandUp;
	-webkit-animation-name: slideExpandUp;	

	animation-duration: 1.6s;	
	-webkit-animation-duration: 1.6s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease -out;

	visibility: visible !important;	
}

@keyframes slideExpandUp {
	0% {
		-webkit-transform: translateY(100%) scaleX(0.5);
		        transform: translateY(100%) scaleX(0.5);
	}
	30%{
		-webkit-transform: translateY(-8%) scaleX(0.5);
		        transform: translateY(-8%) scaleX(0.5);
	}	
	40%{
		-webkit-transform: translateY(2%) scaleX(0.5);
		        transform: translateY(2%) scaleX(0.5);
	}
	50%{
		-webkit-transform: translateY(0%) scaleX(1.1);
		        transform: translateY(0%) scaleX(1.1);
	}
	60%{
		-webkit-transform: translateY(0%) scaleX(0.9);
		        transform: translateY(0%) scaleX(0.9);		
	}
	70% {
		-webkit-transform: translateY(0%) scaleX(1.05);
		        transform: translateY(0%) scaleX(1.05);
	}			
	80%{
		-webkit-transform: translateY(0%) scaleX(0.95);
		        transform: translateY(0%) scaleX(0.95);		
	}
	90% {
		-webkit-transform: translateY(0%) scaleX(1.02);
		        transform: translateY(0%) scaleX(1.02);
	}	
	100%{
		-webkit-transform: translateY(0%) scaleX(1);
		        transform: translateY(0%) scaleX(1);		
	}
}

@-webkit-keyframes slideExpandUp {
	0% {
		-webkit-transform: translateY(100%) scaleX(0.5);
	}
	30%{
		-webkit-transform: translateY(-8%) scaleX(0.5);
	}	
	40%{
		-webkit-transform: translateY(2%) scaleX(0.5);
	}
	50%{
		-webkit-transform: translateY(0%) scaleX(1.1);
	}
	60%{
		-webkit-transform: translateY(0%) scaleX(0.9);		
	}
	70% {
		-webkit-transform: translateY(0%) scaleX(1.05);
	}			
	80%{
		-webkit-transform: translateY(0%) scaleX(0.95);		
	}
	90% {
		-webkit-transform: translateY(0%) scaleX(1.02);
	}	
	100%{
		-webkit-transform: translateY(0%) scaleX(1);		
	}
}

/*
==============================================
expandUp
==============================================
*/


.expandUp{
	animation-name: expandUp;
	-webkit-animation-name: expandUp;	

	animation-duration: 0.7s;	
	-webkit-animation-duration: 0.7s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;		

	visibility: visible !important;	
}

@keyframes expandUp {
	0% {
		-webkit-transform: translateY(100%) scale(0.6) scaleY(0.5);
		        transform: translateY(100%) scale(0.6) scaleY(0.5);
	}
	60%{
		-webkit-transform: translateY(-7%) scaleY(1.12);
		        transform: translateY(-7%) scaleY(1.12);
	}
	75%{
		-webkit-transform: translateY(3%);
		        transform: translateY(3%);
	}	
	100% {
		-webkit-transform: translateY(0%) scale(1) scaleY(1);
		        transform: translateY(0%) scale(1) scaleY(1);
	}	
}

@-webkit-keyframes expandUp {
	0% {
		-webkit-transform: translateY(100%) scale(0.6) scaleY(0.5);
	}
	60%{
		-webkit-transform: translateY(-7%) scaleY(1.12);
	}
	75%{
		-webkit-transform: translateY(3%);
	}	
	100% {
		-webkit-transform: translateY(0%) scale(1) scaleY(1);
	}	
}

/*
==============================================
fadeIn
==============================================
*/

.fadeIn{
	animation-name: fadeIn;
	-webkit-animation-name: fadeIn;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;		

	visibility: visible !important;	
}

@keyframes fadeIn {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0);
		opacity: 0.0;		
	}
	60% {
		-webkit-transform: scale(1.1);
		        transform: scale(1.1);	
	}
	80% {
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
		opacity: 1;	
	}	
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		opacity: 1;	
	}		
}

@-webkit-keyframes fadeIn {
	0% {
		-webkit-transform: scale(0);
		opacity: 0.0;		
	}
	60% {
		-webkit-transform: scale(1.1);
	}
	80% {
		-webkit-transform: scale(0.9);
		opacity: 1;	
	}	
	100% {
		-webkit-transform: scale(1);
		opacity: 1;	
	}		
}

/*
==============================================
expandOpen
==============================================
*/


.expandOpen{
	animation-name: expandOpen;
	-webkit-animation-name: expandOpen;	

	animation-duration: 1.2s;	
	-webkit-animation-duration: 1.2s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	visibility: visible !important;	
}

@keyframes expandOpen {
	0% {
		-webkit-transform: scale(1.8);
		        transform: scale(1.8);		
	}
	50% {
		-webkit-transform: scale(0.95);
		        transform: scale(0.95);
	}	
	80% {
		-webkit-transform: scale(1.05);
		        transform: scale(1.05);
	}
	90% {
		-webkit-transform: scale(0.98);
		        transform: scale(0.98);
	}	
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}			
}

@-webkit-keyframes expandOpen {
	0% {
		-webkit-transform: scale(1.8);		
	}
	50% {
		-webkit-transform: scale(0.95);
	}	
	80% {
		-webkit-transform: scale(1.05);
	}
	90% {
		-webkit-transform: scale(0.98);
	}	
	100% {
		-webkit-transform: scale(1);
	}					
}

/*
==============================================
bigEntrance
==============================================
*/


.bigEntrance{
	animation-name: bigEntrance;
	-webkit-animation-name: bigEntrance;	

	animation-duration: 1.6s;	
	-webkit-animation-duration: 1.6s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	visibility: visible !important;			
}

@keyframes bigEntrance {
	0% {
		-webkit-transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
		        transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
		opacity: 0.2;
	}
	30% {
		-webkit-transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
		        transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);		
		opacity: 1;
	}
	45% {
		-webkit-transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
		        transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	60% {
		-webkit-transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
		        transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	75% {
		-webkit-transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
		        transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	90% {
		-webkit-transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
		        transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	100% {
		-webkit-transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
		        transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
		opacity: 1;
	}		
}

@-webkit-keyframes bigEntrance {
	0% {
		-webkit-transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
		opacity: 0.2;
	}
	30% {
		-webkit-transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);		
		opacity: 1;
	}
	45% {
		-webkit-transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	60% {
		-webkit-transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	75% {
		-webkit-transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1;
	}
	90% {
		-webkit-transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);		
		opacity: 1;
	}	
	100% {
		-webkit-transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
		opacity: 1;
	}				
}

/*
==============================================
hatch
==============================================
*/

.hatch{
	animation-name: hatch;
	-webkit-animation-name: hatch;	

	animation-duration: 2s;	
	-webkit-animation-duration: 2s;

	animation-timing-function: ease-in-out;	
	-webkit-animation-timing-function: ease-in-out;

	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%; 

	visibility: visible !important;		
}

@keyframes hatch {
	0% {
		-webkit-transform: rotate(0deg) scaleY(0.6);
		        transform: rotate(0deg) scaleY(0.6);
	}
	20% {
		-webkit-transform: rotate(-2deg) scaleY(1.05);
		        transform: rotate(-2deg) scaleY(1.05);
	}
	35% {
		-webkit-transform: rotate(2deg) scaleY(1);
		        transform: rotate(2deg) scaleY(1);
	}
	50% {
		-webkit-transform: rotate(-2deg);
		        transform: rotate(-2deg);
	}	
	65% {
		-webkit-transform: rotate(1deg);
		        transform: rotate(1deg);
	}	
	80% {
		-webkit-transform: rotate(-1deg);
		        transform: rotate(-1deg);
	}		
	100% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}									
}

@-webkit-keyframes hatch {
	0% {
		-webkit-transform: rotate(0deg) scaleY(0.6);
	}
	20% {
		-webkit-transform: rotate(-2deg) scaleY(1.05);
	}
	35% {
		-webkit-transform: rotate(2deg) scaleY(1);
	}
	50% {
		-webkit-transform: rotate(-2deg);
	}	
	65% {
		-webkit-transform: rotate(1deg);
	}	
	80% {
		-webkit-transform: rotate(-1deg);
	}		
	100% {
		-webkit-transform: rotate(0deg);
	}		
}


/*
==============================================
bounce
==============================================
*/


.bounce{
	animation-name: bounce;
	-webkit-animation-name: bounce;	

	animation-duration: 1.6s;	
	-webkit-animation-duration: 1.6s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;	
	
	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%; 	
}

@keyframes bounce {
	0% {
		-webkit-transform: translateY(0%) scaleY(0.6);
		        transform: translateY(0%) scaleY(0.6);
	}
	60%{
		-webkit-transform: translateY(-100%) scaleY(1.1);
		        transform: translateY(-100%) scaleY(1.1);
	}
	70%{
		-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1.05);
		        transform: translateY(0%) scaleY(0.95) scaleX(1.05);
	}
	80%{
		-webkit-transform: translateY(0%) scaleY(1.05) scaleX(1);
		        transform: translateY(0%) scaleY(1.05) scaleX(1);
	}	
	90%{
		-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1);
		        transform: translateY(0%) scaleY(0.95) scaleX(1);
	}				
	100%{
		-webkit-transform: translateY(0%) scaleY(1) scaleX(1);
		        transform: translateY(0%) scaleY(1) scaleX(1);
	}	
}

@-webkit-keyframes bounce {
	0% {
		-webkit-transform: translateY(0%) scaleY(0.6);
	}
	60%{
		-webkit-transform: translateY(-100%) scaleY(1.1);
	}
	70%{
		-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1.05);
	}
	80%{
		-webkit-transform: translateY(0%) scaleY(1.05) scaleX(1);
	}	
	90%{
		-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1);
	}				
	100%{
		-webkit-transform: translateY(0%) scaleY(1) scaleX(1);
	}		
}


/*
==============================================
pulse
==============================================
*/

.pulse{
	animation-name: pulse;
	-webkit-animation-name: pulse;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
		opacity: 0.7;		
	}
	50% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		opacity: 1;	
	}	
	100% {
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
		opacity: 0.7;	
	}			
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(0.95);
		opacity: 0.7;		
	}
	50% {
		-webkit-transform: scale(1);
		opacity: 1;	
	}	
	100% {
		-webkit-transform: scale(0.95);
		opacity: 0.7;	
	}			
}

/*
==============================================
floating
==============================================
*/

.floating{
	animation-name: floating;
	-webkit-animation-name: floating;

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes floating {
	0% {
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);	
	}
	50% {
		-webkit-transform: translateY(8%);
		        transform: translateY(8%);	
	}	
	100% {
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}			
}

@-webkit-keyframes floating {
	0% {
		-webkit-transform: translateY(0%);	
	}
	50% {
		-webkit-transform: translateY(8%);	
	}	
	100% {
		-webkit-transform: translateY(0%);
	}			
}

/*
==============================================
tossing
==============================================
*/

.tossing{
	animation-name: tossing;
	-webkit-animation-name: tossing;	

	animation-duration: 2.5s;	
	-webkit-animation-duration: 2.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes tossing {
	0% {
		-webkit-transform: rotate(-4deg);
		        transform: rotate(-4deg);	
	}
	50% {
		-webkit-transform: rotate(4deg);
		        transform: rotate(4deg);
	}
	100% {
		-webkit-transform: rotate(-4deg);
		        transform: rotate(-4deg);	
	}						
}

@-webkit-keyframes tossing {
	0% {
		-webkit-transform: rotate(-4deg);	
	}
	50% {
		-webkit-transform: rotate(4deg);
	}
	100% {
		-webkit-transform: rotate(-4deg);	
	}				
}

/*
==============================================
pullUp
==============================================
*/

.pullUp{
	animation-name: pullUp;
	-webkit-animation-name: pullUp;	

	animation-duration: 1.1s;	
	-webkit-animation-duration: 1.1s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%; 		
}

@keyframes pullUp {
	0% {
		-webkit-transform: scaleY(0.1);
		        transform: scaleY(0.1);
	}
	40% {
		-webkit-transform: scaleY(1.02);
		        transform: scaleY(1.02);
	}
	60% {
		-webkit-transform: scaleY(0.98);
		        transform: scaleY(0.98);
	}
	80% {
		-webkit-transform: scaleY(1.01);
		        transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(0.98);
		        transform: scaleY(0.98);
	}				
	80% {
		-webkit-transform: scaleY(1.01);
		        transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(1);
		        transform: scaleY(1);
	}							
}

@-webkit-keyframes pullUp {
	0% {
		-webkit-transform: scaleY(0.1);
	}
	40% {
		-webkit-transform: scaleY(1.02);
	}
	60% {
		-webkit-transform: scaleY(0.98);
	}
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(0.98);
	}				
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(1);
	}		
}

/*
==============================================
pullDown
==============================================
*/

.pullDown{
	animation-name: pullDown;
	-webkit-animation-name: pullDown;	

	animation-duration: 1.1s;	
	-webkit-animation-duration: 1.1s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 50% 0%;
	-ms-transform-origin: 50% 0%;
	-webkit-transform-origin: 50% 0%; 		
}

@keyframes pullDown {
	0% {
		-webkit-transform: scaleY(0.1);
		        transform: scaleY(0.1);
	}
	40% {
		-webkit-transform: scaleY(1.02);
		        transform: scaleY(1.02);
	}
	60% {
		-webkit-transform: scaleY(0.98);
		        transform: scaleY(0.98);
	}
	80% {
		-webkit-transform: scaleY(1.01);
		        transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(0.98);
		        transform: scaleY(0.98);
	}				
	80% {
		-webkit-transform: scaleY(1.01);
		        transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(1);
		        transform: scaleY(1);
	}							
}

@-webkit-keyframes pullDown {
	0% {
		-webkit-transform: scaleY(0.1);
	}
	40% {
		-webkit-transform: scaleY(1.02);
	}
	60% {
		-webkit-transform: scaleY(0.98);
	}
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(0.98);
	}				
	80% {
		-webkit-transform: scaleY(1.01);
	}
	100% {
		-webkit-transform: scaleY(1);
	}		
}

/*
==============================================
stretchLeft
==============================================
*/

.stretchLeft{
	animation-name: stretchLeft;
	-webkit-animation-name: stretchLeft;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 100% 0%;
	-ms-transform-origin: 100% 0%;
	-webkit-transform-origin: 100% 0%; 
}

@keyframes stretchLeft {
	0% {
		-webkit-transform: scaleX(0.3);
		        transform: scaleX(0.3);
	}
	40% {
		-webkit-transform: scaleX(1.02);
		        transform: scaleX(1.02);
	}
	60% {
		-webkit-transform: scaleX(0.98);
		        transform: scaleX(0.98);
	}
	80% {
		-webkit-transform: scaleX(1.01);
		        transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(0.98);
		        transform: scaleX(0.98);
	}				
	80% {
		-webkit-transform: scaleX(1.01);
		        transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(1);
		        transform: scaleX(1);
	}							
}

@-webkit-keyframes stretchLeft {
	0% {
		-webkit-transform: scaleX(0.3);
	}
	40% {
		-webkit-transform: scaleX(1.02);
	}
	60% {
		-webkit-transform: scaleX(0.98);
	}
	80% {
		-webkit-transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(0.98);
	}				
	80% {
		-webkit-transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(1);
	}		
}

/*
==============================================
stretchRight
==============================================
*/

.stretchRight{
	animation-name: stretchRight;
	-webkit-animation-name: stretchRight;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-timing-function: ease-out;	
	-webkit-animation-timing-function: ease-out;	

	transform-origin: 0% 0%;
	-ms-transform-origin: 0% 0%;
	-webkit-transform-origin: 0% 0%; 		
}

@keyframes stretchRight {
	0% {
		-webkit-transform: scaleX(0.3);
		        transform: scaleX(0.3);
	}
	40% {
		-webkit-transform: scaleX(1.02);
		        transform: scaleX(1.02);
	}
	60% {
		-webkit-transform: scaleX(0.98);
		        transform: scaleX(0.98);
	}
	80% {
		-webkit-transform: scaleX(1.01);
		        transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(0.98);
		        transform: scaleX(0.98);
	}				
	80% {
		-webkit-transform: scaleX(1.01);
		        transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(1);
		        transform: scaleX(1);
	}							
}

@-webkit-keyframes stretchRight {
	0% {
		-webkit-transform: scaleX(0.3);
	}
	40% {
		-webkit-transform: scaleX(1.02);
	}
	60% {
		-webkit-transform: scaleX(0.98);
	}
	80% {
		-webkit-transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(0.98);
	}				
	80% {
		-webkit-transform: scaleX(1.01);
	}
	100% {
		-webkit-transform: scaleX(1);
	}		
}
