.game-detail {
  width: 100%;
  padding: 10px;
  background: #f8f9fa;
  border: 2px solid #154b8b;
  box-shadow: 1px 1px 1px #154b8b;
  border-radius: 5px;
  margin: 10px auto;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: inherit !important;
}

.buttons-section {
  margin-top: 10px;
}

@media only screen and (min-width: 801px) {
  .game-detail {
    width: 60%;
    /* animation: fadeIn .6s ease-in-out; */
  }
}

/* @keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */
